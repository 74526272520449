import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import roger from "assets/img/team/roger.jpg";
import robin from "assets/img/team/robin.jpg";
import unknown from "assets/img/team/unknown.jpg";

import { cardTitle, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const teamStyle = {
  section: {
    padding: "10px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  }
};

class BaseTeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section}>
        <h2 className={classes.title}>Here is our team</h2>
        <div>
          <GridContainer justify="space-around">
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={roger} alt="Roger" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Roger
                  <br />
                  <small className={classes.smallTitle}>CEO</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*</p>*/}
                </CardBody>
                {/*<CardFooter className={classes.justifyCenter}>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaTwitter/>*/}
                {/*  </Button>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaInstagram/>*/}
                {/*  </Button>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaFacebook/>*/}
                {/*  </Button>*/}
                {/*</CardFooter>*/}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={robin} alt="Robin" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Robin
                  <br />
                  <small className={classes.smallTitle}>Sales Manager</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            {/*<GridItem xs={12} sm={12} md={4}>*/}
            {/*  <Card plain>*/}
            {/*    <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>*/}
            {/*      <img src={unknown} alt="..." className={imageClasses} />*/}
            {/*    </GridItem>*/}
            {/*    <h4 className={classes.cardTitle}>*/}
            {/*      John Doe*/}
            {/*      <br />*/}
            {/*      <small className={classes.smallTitle}>HR Manager</small>*/}
            {/*    </h4>*/}
            {/*    /!*<CardBody>*!/*/}
            {/*    /!*  <p className={classes.description}>*!/*/}
            {/*    /!*    You can write here details about one of your team members.*!/*/}
            {/*    /!*    You can give more details about what they do.*!/*/}
            {/*    /!*  </p>*!/*/}
            {/*    /!*</CardBody>*!/*/}
            {/*  </Card>*/}
            {/*</GridItem>*/}
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(teamStyle)(BaseTeamSection);
