import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import macarena from "assets/img/team/macarena.jpg";
import lucas from "assets/img/team/lucas.jpg";
import nico from "assets/img/team/nico.jpg";
import esteban from "assets/img/team/esteban.jpg";
import unknown from "assets/img/team/unknown.jpg";

import { cardTitle, title } from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const creativeTeamStyle = {
  section: {
    padding: "30px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  ...imagesStyle,
  itemGrid: {
    marginLeft: "auto",
    marginRight: "auto"
  },
  cardTitle,
  smallTitle: {
    color: "#6c757d"
  },
  description: {
    color: "#999"
  },
  justifyCenter: {
    justifyContent: "center !important"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  margin5: {
    margin: "5px"
  }
};


class CreativeTeamSection extends React.Component {
  render() {
    const { classes } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    return (
      <div className={classes.section} style={{"backgroundColor": "#f4f0f0"}}>
        <h3 className={classes.title}>Creative team</h3>
        <div>
          <GridContainer justify="space-around">
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={macarena} alt="Macarena" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Macarena
                  <br />
                  <small className={classes.smallTitle}>Producer & Creative Director</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={lucas} alt="Lucas" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Lucas
                  <br />
                  <small className={classes.smallTitle}>Director</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*</p>*/}
                </CardBody>
                {/*<CardFooter className={classes.justifyCenter}>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaTwitter/>*/}
                {/*  </Button>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaInstagram/>*/}
                {/*  </Button>*/}
                {/*  <Button*/}
                {/*    justIcon*/}
                {/*    color="transparent"*/}
                {/*    className={classes.margin5}*/}
                {/*  >*/}
                {/*    <FaFacebook/>*/}
                {/*  </Button>*/}
                {/*</CardFooter>*/}
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={nico} alt="Nico" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Nico
                  <br />
                  <small className={classes.smallTitle}>Director</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={esteban} alt="Esteban" className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Esteban
                  <br />
                  <small className={classes.smallTitle}>Motion Design</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={unknown} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Ivanna
                  <br />
                  <small className={classes.smallTitle}>Photography</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={unknown} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Nicolas C.
                  <br />
                  <small className={classes.smallTitle}>Strategy</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card plain>
                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                  <img src={unknown} alt="..." className={imageClasses} />
                </GridItem>
                <h4 className={classes.cardTitle}>
                  Nicolas A.
                  <br />
                  <small className={classes.smallTitle}>Post Production</small>
                </h4>
                <CardBody>
                  {/*<p className={classes.description}>*/}
                  {/*  You can write here details about one of your team members.*/}
                  {/*  You can give more details about what they do.*/}
                  {/*</p>*/}
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(creativeTeamStyle)(CreativeTeamSection);
