import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import makal from "assets/img/team/makal.jpg";
import giorgid from "assets/img/team/giorgid.jpg";
import iraklig from "assets/img/team/iraklig.jpg";
import unknown from "assets/img/team/unknown.jpg";


import {cardTitle, title} from "assets/jss/material-kit-react.jsx";
import imagesStyle from "assets/jss/material-kit-react/imagesStyles.jsx";

const teamStyle = {
    section: {
        padding: "10px 0",
        textAlign: "center"
    },
    title: {
        ...title,
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
    },
    ...imagesStyle,
    itemGrid: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    cardTitle,
    smallTitle: {
        color: "#6c757d"
    },
    description: {
        color: "#999"
    },
    justifyCenter: {
        justifyContent: "center !important"
    },
    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    margin5: {
        margin: "5px"
    }
};


class DigitalTeamSection extends React.Component {
    render() {
        const {classes} = this.props;
        const imageClasses = classNames(
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid
        );
        return (
            <div className={classes.section}>
                <h3 className={classes.title}>Digital team</h3>
                <div>
                    <GridContainer justify="space-around">
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                                    <img src={makal} alt="..." className={imageClasses}/>
                                </GridItem>
                                <h4 className={classes.cardTitle}>
                                    Maka
                                    <br/>
                                    <small className={classes.smallTitle}>Full Stack Developer</small>
                                </h4>
                                <CardBody>
                                    {/*<p className={classes.description}>*/}
                                    {/*  You can write here details about one of your team members.*/}
                                    {/*</p>*/}
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                                    <img src={giorgid} alt="..." className={imageClasses}/>
                                </GridItem>
                                <h4 className={classes.cardTitle}>
                                    Giorgi
                                    <br/>
                                    <small className={classes.smallTitle}>Backend Developer</small>
                                </h4>
                                <CardBody>
                                    {/*<p className={classes.description}>*/}
                                    {/*  You can write here details about one of your team members.*/}
                                    {/*  You can give more details about what they do.*/}
                                    {/*</p>*/}
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                            <Card plain>
                                <GridItem xs={12} sm={6} md={6} className={classes.itemGrid}>
                                    <img src={iraklig} alt="..." className={imageClasses}/>
                                </GridItem>
                                <h4 className={classes.cardTitle}>
                                    Irakli
                                    <br/>
                                    <small className={classes.smallTitle}>Frontend Developer</small>
                                </h4>
                                <CardBody>
                                    {/*<p className={classes.description}>*/}
                                    {/*  You can write here details about one of your team members.*/}
                                    {/*  You can give more details about what they do.*/}
                                    {/*</p>*/}
                                </CardBody>
                            </Card>

                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        );
    }
}

export default withStyles(teamStyle)(DigitalTeamSection);
