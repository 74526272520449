import React from "react";
import {createMemoryHistory} from "history";
import {Route, Router, Switch} from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';
// pages for this product
import LandingPage from "./LandingPage/LandingPage.jsx";
import CreativePage from "./CreativePage/CreativePage.jsx";
import DigitalPage from "./DigitalPage/DigitalPage.jsx";
// import LoginPage from "./LoginPage/LoginPage.jsx";
import CareerPage from "./CareerPage/CareerPage.jsx";
import ContactPage from "./ContactPage/ContactPage.jsx";
import TeamPage from "./TeamPage/TeamPage";

let hist = createMemoryHistory();

export default () => (
  <Router history={hist}>
    <Switch>
      <Route path="/" component={LandingPage} />
      <Route path="/creative" component={CreativePage} />
      <Route path="/digital" component={DigitalPage} />
      {/*<Route path="/login" component={LoginPage} />*/}
      <Route path="/career" component={CareerPage} />
      <Route path="/team" component={TeamPage} />
      <Route path="/contact" component={ContactPage} />
    </Switch>
  </Router>
);
