import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import teamPageStyle from "assets/jss/material-kit-react/views/teamPage.jsx";

// Sections for this page
import BaseTeamSection from "./Sections/BaseTeamSection.jsx";
import CreativeTeamSection from "./Sections/CreativeTeamSection";
import DigitalTeamSection from "./Sections/DigitalTeamSection";


class TeamPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Nari"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/hero/mtatsminda.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <BaseTeamSection />
            <CreativeTeamSection />
            <DigitalTeamSection />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(teamPageStyle)(TeamPage);
