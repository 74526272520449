import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DonutSmall from "@material-ui/icons/DonutSmall";
import FavoriteBorder from "@material-ui/icons/FavoriteBorder";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import Button from "../../../components/CustomButtons/Button";
import {Link} from "gatsby";

class SplitSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
          <GridContainer>
            {/*<GridItem xs={12} sm={12} md={6}>*/}
            {/*  <InfoArea*/}
            {/*    title="Nari Creative"*/}
            {/*    description="Does your business need support from a graphic designer? Or maybe a social media boost?*/}
            {/*                 Our integrated agency provides these services, among many others."*/}
            {/*    icon={FavoriteBorder}*/}
            {/*    iconColor="info"*/}
            {/*    vertical*/}
            {/*  />*/}
            {/*  <br />*/}
            {/*    <Button*/}
            {/*        component={Link}*/}
            {/*        to="/creative"*/}
            {/*        color="danger"*/}
            {/*        size="lg"*/}
            {/*    >*/}
            {/*      Learn More*/}
            {/*    </Button>*/}
            {/*</GridItem>*/}
            <GridItem xs={12} sm={12} md={12}>
              <InfoArea
                title="Nari Solutions"
                description="Scale your business to the next level with our expert software engineering teams.
                             We provide a wide range of IT consultancy and project management services."
                icon={DonutSmall}
                iconColor="success"
                vertical
              />
              <br />
                <Button
                    component={Link}
                    to="/digital"
                    color="danger"
                    size="lg"
                >
                  Learn More
                </Button>
            </GridItem>
          </GridContainer>
      </div>
    );
  }
}

export default withStyles(productStyle)(SplitSection);
